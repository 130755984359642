import $ from "jquery";

import("swiper/scss");
import("swiper/scss/navigation");
import("swiper/scss/effect-fade");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Navigation, Pagination, EffectFade } = await import("swiper/modules");

  $(".hero-banner--type-1").each(function () {
    const leftSlider = new Swiper($(this).find(".block--left .swiper")[0], {
      modules: [Pagination, EffectFade],
      slidesPerView: 1,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: $(this).find(".progress-bar")[0],
        type: "progressbar",
      },
    });

    const rightSlider = new Swiper($(this).find(".block--right .swiper")[0], {
      modules: [Navigation, EffectFade],
      slidesPerView: 1,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: $(this).find(".nav--next")[0],
        prevEl: $(this).find(".nav--prev")[0],
      },
    });

    leftSlider.on("slideChange", () => {
      rightSlider.slideTo(leftSlider.activeIndex);
    });

    rightSlider.on("slideChange", () => {
      leftSlider.slideTo(rightSlider.activeIndex);
    });
  });
})();
